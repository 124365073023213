<!-- 文件中文名: 游轮游信息管理-->
<template>
  <div class="CruiseInformation">
    <app-breadcrumb/>
    <div class="page-container">
      <div class="filter">
        <el-form :inline="true" :model="listQuery" label-width="90px" size="mini">
          <el-form-item v-if="isAgent" label="资格人编号:" class="memberNo-css input-red">
            <el-input v-model.trim="listQuery.qualifiedNo" style="width:160px;" clearable/>
          </el-form-item>
          <el-form-item style="margin-left:5px;">
            <el-button v-if="isAgent" type="primary" plain icon="el-icon-search" @click="handleFilter">查询</el-button>
            <el-button v-if="permissions.includes('/luruzanwu') && isAgent && team" type="primary" icon="el-icon-plus"
                       @click="handleViewEdit({id:null}, 'new')">录入信息
            </el-button>
            <el-popover trigger="hover">
              <el-table :data="gridData" border max-height="200">
                <el-table-column width="120" prop="memberNo" label="经销商编号" align="center"></el-table-column>
                <el-table-column prop="qualifications" label="剩余资格数" align="center"></el-table-column>
              </el-table>
              <el-button v-if="isAgent" slot="reference" style="margin-left: 10px">剩余资格总数: {{ remainingQty }}
              </el-button>
            </el-popover>
            <!--            <el-button v-if="permissions.includes('/memberPassport/roomSave')" style="margin-left: 10px"  type="primary" icon="el-icon-plus"
                       @click="Allocationroomtypes">分配房型
            </el-button>-->
            <span v-if="permissions.includes('/memberPassport/roomSave')"> 未入住人数：<span style="color: red">{{ this.residentsnum }}</span></span>
            <!--            <span v-if="permissions.includes('/memberPassport/roomSave')"> 未分房数：<span style="color: red">{{ this.roomNonum }}</span></span>-->
          </el-form-item>
        </el-form>
      </div>
      <el-table ref="memberTable" v-loading="doLoading" :data="list" style="width: 100%" size="mini" border stripe>
        <el-table-column
            label="操作"
            align="center"
            fixed
            width="120">
          <template slot-scope="scope">
            <el-button title="查看" size="mini" icon="el-icon-search" circle
                       @click="handleViewEdit(scope.row, 'view')"/>
            <el-button title="编辑" size="mini" icon="el-icon-edit" type="warning" circle
                       @click="handleViewEdit(scope.row, 'edit')" v-if="handleEditShow(scope.row)"/>
            <el-button v-if="team && isAgent && scope.row.checkStatus === '3'" title="分配" size="mini" icon="el-icon-setting" type="info" circle
                       @click="distributionBtn(scope.row)"/>
            <!--            <el-button title="删除" size="mini" icon="el-icon-delete" type="danger" circle
                       @click="handleDelete(scope.row)"
                       v-if="handleDeleteShow(scope.row)"/>-->
          </template>
        </el-table-column>
        <el-table-column label="审核状态" prop="checkStatus" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.checkStatus | checkStatusFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="驳回原因" align="center">
          <template slot-scope="scope">
            <p v-if="scope.row.checkStatus==='2'" style="color: red">初审: {{ scope.row.cancelRemark }}</p>
            <p v-if="scope.row.checkStatus==='4'" style="color: red">二审: {{ scope.row.cancelRemark2 }}</p>
          </template>
        </el-table-column>
        <el-table-column label="资格人编号" prop="qualifiedNo" align="center"/>
        <el-table-column label="出行人姓名" prop="memberName" align="center"/>
        <el-table-column label="出行人性别" prop="sex" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.sex | sexFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出行人手机号" prop="mobile" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.mobile | mobileFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出行人身份证号" prop="idCard" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.idCard | idCardFilter }}</span>
          </template>
        </el-table-column>
        <el-table-column label="出行人护照号" prop="passport" align="center"/>
        <el-table-column v-if="isAgent" label="房型" prop="roomType" align="center">
          <template v-slot="{row}">
            {{ $lt(initDict.roomtype,row.roomType) }}
          </template>
        </el-table-column>
        <el-table-column v-if="isAgent" label="房号" prop="roomNo" align="center"/>
        <el-table-column v-if="isAgent" label="同住人" prop="residents" align="center"/>
      </el-table>
      <pagination :total="total" :page.sync="listQuery.page" :limit.sync="listQuery.limit" @pagination="getList"/>
    </div>
    <div class="page-container">
      <el-dialog :close-on-click-modal="false" :show-close="false" :visible.sync="Assign_rooms">
        <el-form :model="listQuery1"  :rules="rules"  label-width="120px"  ref="listQuery1" size="mini" >
          <el-form-item label="选择房型:">
            双人间
          </el-form-item>
          <el-form-item label="当前人:">
            {{ listQuery1.memberName }}
          </el-form-item>
          <!--          <el-form-item label="选择房型:" prop="roomType">
            <el-select v-model="listQuery1.roomType" @change="GetroomType"  clearable placeholder="请选择">
              <el-option
                  v-for="item in roomTypeList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="选择房号:" prop="roomNo">
            <el-select v-model="listQuery1.roomNo" @change="GetRoom"  clearable placeholder="请选择">
              <el-option
                  v-for="item in roomNoList"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id"
              >
                <span>{{item.label}}<span v-if="item.miMemberPassportDTOS.length>0"> ({{item.miMemberPassportDTOS.length}})</span></span>
              </el-option>
            </el-select>
          </el-form-item>-->
          <el-form-item label="选择入住人:"  required>
            <el-select v-model="listQuery1.residents" clearable placeholder="请选择">
              <el-option v-for="item in screenResidentsList" :key="item.id"  :label="item.memberName" :value="item.id">
                <span>{{item.memberNo}}</span>——<span>{{item.idCardFour}}</span>——<span>{{item.memberName}}</span>——<span>{{item.sex === '0' ? '女' : '男'}}</span>
                <span v-if="!item.residents">——未入住</span><span v-if="item.residents">——已入住{{item.roomNo}}</span>
              </el-option>
            </el-select>
            <span style="margin-left: 5px;font-size: 12px;color: green">可根据姓名搜索</span>
          </el-form-item>
          <el-form-item>
            <el-button @click="Assign_rooms = false">取消</el-button>
            <el-button type="primary" @click="onSubmit('listQuery1')">保存</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import {
  checkNumberQual,
  memberPassportDropById,
  memberPassportPageByDTO, peopleBack, roomBack, roomView, saveResidents
} from "@/api/mi/memberPassport";
import {mapGetters} from "vuex";

export default {
  name: 'CruiseInformation',
  // import引入的组件需要注入到对象中才能使用",
  components: {Pagination},
  // 作为子组件传入数据
  props: {},
  data() {
    return {
      teamList: [
        'CNA9110317A',
        'CNA87786966',
        'CNA88513376',
        'CNA86177733',
        'CNA81109285',
        'CNA86162898',
        'CNA82826828',
        'CNA81767038',
        'CNA81013770',
        'CNA82663208',
        'CNA85736601',
        'CNA9180513A',
        'CNA88376589',
        'CNA83558132',
        'CNA85522890',
        'CNA82950123',
        'CNA86090128',
        'CNA87888006',
        'CNA82791760',
        'CNA88760809'
      ],
      team: true,
      // 分配房型
      Assign_rooms:false,
      listQuery1:{
        id: '',
        memberName: '',
        residents:''
      },
      initDict:{
        roomtype:'room.type'
      },
      // 房型
      roomTypeList:[
        {label:'内仓房(2人间)',value:1},
        // {label:'内仓房(3人间)',value:2},
        {label:'海景房(2人间)',value:3},
        {label:'海景房(3人间)',value:4},
        {label:'阳台房(2人间)',value:5},
        {label:'阳台房(3人间)',value:6},
        {label:'巴伐利亚(2人间)',value:7},
        {label:'巴伐利亚(3人间)',value:8},
      ],
      screenResidentsList: [],
      residentsList:[],//入住人
      residentsnum:0,//未入住人数量
      roomNonum:0,//未分配房数
      roomNoList:[],//房号
      maxNumber:0,//最大入住人,
      rules:{
        roomType: [{ required: true, message: '请选择房型', trigger: 'change' }],
        roomNo: [{ required: true, message: '请选择房号', trigger: 'change' }],
      },

      remainingQty: 0,
      gridData: [],
      list: [],
      doLoading: false,
      total: 0,
      listQuery: {
        qualifiedNo: null,
        page: 1,
        limit: 10
      },
    }
  },
  filters: {
    mobileFilter(value) {
      return value.slice(0, 3) + '****' + value.slice(-4);
    },
    idCardFilter(value) {
      return value.slice(0, 3) + '************' + value.slice(-4);
    },
    sexFilter(value) {
      return value === '1' ? '男' : '女'
    },
    checkStatusFilter(value) {
      return value === '0' ? '未审核' : value === '1' ? '初审通过' : value === '2' ? '初审驳回' : value === '3' ? '二审通过' : '二审驳回'
    }
  },
  computed: {
    ...mapGetters([
      'isAgent',
      'userCode',
      'permissions'
    ]),
  },
  created() {
    this.getList()
  },
  mounted() {
    try {
      this.teamList.forEach(value => {
        if (value === this.userCode) {
          throw ('终止')
        }
      })
    }catch (e) {
      this.team = false
    }
  },
  methods: {
    distributionBtn(row) {
      this.Assign_rooms = true
      this.listQuery1.id = row.id
      this.listQuery1.memberName = row.memberName
      this.screenResidentsList = this.residentsList.filter(value => value.id !== this.listQuery1.id)
      this.listQuery1.residents = row.residents
    },
    // 分配房型
    Allocationroomtypes(){
      this.Assign_rooms = true
      this.listQuery1.residents = [] //入住人
      // this.roomNoList = [] //房号
      // this.listQuery1.roomNo = '' //房号
      // this.listQuery1.roomType = '' // 房型
      this.listQuery1.cohabitant = '' //入住人拼接后传值
      this.listQuery1.id = ''
    },
    onSubmit(formName){
      if(!this.listQuery1.residents){
        return this.$message.error('请选择入住人')
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveResidents({...this.listQuery1}).then(() =>{
            this.$message.success('操作成功')
            this.Assign_rooms = false
            this.$refs[formName].resetFields();
            this.getList()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });

    },
    onCancel(formName){
      this.Assign_rooms = false
      this.$refs[formName].resetFields();
      this.listQuery1.id = ''
      this.listQuery1.residents = [] //入住人
      // this.roomNoList = [] //房号
      // this.listQuery1.roomNo = '' //房号
      // this.listQuery1.roomType = '' // 房型
      this.listQuery1.cohabitant = '' //入住人拼接后传值
    },
    handleEditShow(row) {
      if (row) {
        return row.checkStatus === '2' || row.checkStatus === '4'
      } else {
        return false
      }
    },
    handleDeleteShow(row) {
      if (row) {
        return row.creater === this.userCode && row.checkStatus !== '3' && this.permissions.includes('/memberPassport/dropById')
      } else {
        return false
      }
    },
    async getList() {
      try {
        this.doLoading = true
        const res1 = await memberPassportPageByDTO({...this.listQuery})
        this.list = res1.data.records
        this.total = +res1.data.total || 0
        if (this.isAgent) {
          const res2 = await checkNumberQual()
          this.gridData = res2.data
          this.remainingQty = 0
          this.gridData.forEach(item => {
            this.remainingQty += Number(item.qualifications)
          })
        }
        this.doLoading = false
      } catch (err) {
        console.error(err)
        this.doLoading = false
      }
      if(this.isAgent){
        roomView().then(res=>{
          let list = []
          res.data.forEach(v=>{
            if(v.checkStatus === '3'){
              list.push(v)
            }
          })
          this.residentsList = list
          this.residentsnum = 0
          list.map(item => {
            if (item.residents) {
              this.residentsnum++
            }
          })
        })
      }
    },
    GetroomType(val){
      this.roomNoList = []
      this.listQuery1.roomNo = ''
      this.listQuery1.residents = []
      if(val){
        roomBack({'roomType':val}).then(res=>{
          if(res.data){
            this.maxNumber = res.data[0].maxNumber
            let obj = {'label':'',id:'',miMemberPassportDTOS:''}
            res.data.forEach(v=>{
              obj = {'label':v.roomNo,id:v.id,miMemberPassportDTOS:v.miMemberPassportDTOS}
              this.roomNoList.push(obj)
            })
          }
        })
      }
    },
    GetRoom(value){
      this.listQuery1.residents = []
      if(value){
        peopleBack({'id':value}).then(res=>{
          res.data.forEach(v=>{
            this.listQuery1.residents.push(v.id)
          })
        })
      }

    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleViewEdit(row, type) {
      sessionStorage.setItem(
          'CruiseInfo',
          JSON.stringify({
                id: row.id,
                status: type
              }
          )
      )
      this.$router.push('/order/cruise-information-save')
    },
    handleDelete(row) {
      this.$confirm('是否确认删除?', "警告", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      }).then(() => {
        this.doLoading = true
        memberPassportDropById({id: row.id}).then(res => {
          this.doLoading = false
          if (res.success) {
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
            this.getList()
          }
        }).catch(err => {
          this.doLoading = false
          console.error(err)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        });
      })
    },
  }
}

</script>

<style scoped>

</style>
