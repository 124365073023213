import request from '@/utils/request'

// 游轮信息管理 分页 前台
export function memberPassportPageByDTO(query) {
  return request({
    url: '/memberPassport/pageByDTO',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 集合 前台
export function listByDTO(query) {
  return request({
    url: '/memberPassport/listByDTO',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 查看 前台
export function memberPassportViewById(query) {
  return request({
    url: '/memberPassport/viewById',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 删除 前台
export function memberPassportDropById(query) {
  return request({
    url: '/memberPassport/dropById',
    method: 'get',
    params: query
  })
}

// 游轮信息管理 更新 前台
export function memberPassportSave(data) {
  return request({
    url: '/memberPassport/save',
    method: 'post',
    data
  })
}

// 游轮信息管理 审核 前台
export function check(query) {
  return request({
    url: '/memberPassport/check',
    method: 'get',
    params: query
  })
}

// 经销商游轮信息验证 前台
export function memberPassportMemberInfo(query) {
  return request({
    url: '/memberPassport/memberInfo',
    method: 'get',
    params: query
  })
}

// 经销商游轮信息管理 获取代办处剩余资格数
export function checkNumberQual(query) {
  return request({
    url: '/memberPassport/checkNumberQual',
    method: 'get',
    params: query
  })
}


// 经销商游轮信息管理 获分配房型获取人数
export function roomView(query) {
  return request({
    url: '/memberPassport/roomView',
    method: 'get',
    params: query
  })
}
// 经销商游轮信息管理 获分配房型获取房号
export function roomBack(query) {
  return request({
    url: '/memberPassport/roomBack',
    method: 'get',
    params: query
  })
}

// 经销商游轮信息管理 根据房号获取入住人
export function peopleBack(query) {
  return request({
    url: '/memberPassport/peopleBack',
    method: 'get',
    params: query
  })
}

// 经销商游轮信息管理 房型房号入住人分配保存
export function roomSave(data) {
  return request({
    url: '/memberPassport/roomSave',
    method: 'post',
    data
  })
}

// 房型房号入住人分配保存
export function saveResidents(data) {
  return request({
    url: '/memberPassport/saveResidents',
    method: 'post',
    data
  })
}

// 根据身份证获取岁数
export function IdCard(query) {
  return request({
    url: '/memberPassport/IdCard',
    method: 'get',
    params: query
  })
}
